import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
} from "date-fns";


export const calculateDaysDifference = (createdDate) => {
  if (createdDate) {
    const createdDateObj = new Date(createdDate);
    const currentDate = new Date();
    const diffInSeconds = differenceInSeconds(currentDate, createdDateObj);
    const diffInMinutes = differenceInMinutes(currentDate, createdDateObj);
    const diffInHours = differenceInHours(currentDate, createdDateObj);
    const diffInDays = differenceInDays(currentDate, createdDateObj);
    const diffInMonths = differenceInMonths(currentDate, createdDateObj);

    if (diffInSeconds < 60) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return diffInMinutes === 1
        ? "1 minute ago"
        : `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return diffInHours === 1 ? "1 hour ago" : `${diffInHours} hours ago`;
    } else if (diffInMonths > 0) {
      return diffInMonths === 1 ? "1 month ago" : `${diffInMonths} months ago`;
    } else {
      return diffInDays === 1 ? "1 day ago" : `${diffInDays} days ago`;
    }
  } else {
    return "";
  }
};

export const extractHeadings = (htmlContent) => {
  const div = document.createElement("div");
  div.innerHTML = htmlContent;

  const headings = div.querySelectorAll("h1, h2, h3, h4, h5, h6");

  let contentHeading = Array.from(headings).map((heading, index) => {
    const id = heading.id || `heading-${index}-${heading.innerText.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`;
    heading.id = id; // Assign the generated ID to the heading

    return {
      text: heading.innerText,
      level: parseInt(heading.tagName.replace("H", "")),
      id: id,
    };
  });
  return contentHeading.filter(x=>x.text != '')
};

export const updateHeadingsInHtml = (htmlContent) => {
  const div = document.createElement("div");
  div.innerHTML = htmlContent;

  const headings = div.querySelectorAll("h1, h2, h3, h4, h5, h6");

  Array.from(headings).forEach((heading, index) => {
    const id =
      heading.id ||
      `heading-${index}-${heading.innerText
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")}`;
    heading.id = id;
  });

  return div.innerHTML;
};

export const scrollToId = (event,id) =>{
  event.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}