import axios from "axios";

class APIService {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        // 'Cache-Control': 'no-cache',
      },
      withCredentials: false,
    });
    // const token = getCookies('ormuser');
    const token = process.env.REACT_APP_API_TOKEN;
    if (token) {
      this.service.defaults.headers.common["x-auth-token"] = token;
    }
  }

  async retryRequest(requestFunction, path, payload, headers) {
    let retries = 3;

    while (retries > 0) {
      try {
        const response = await requestFunction(path, payload, headers);
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.status >= 500) {
            // Decrement retries on failure
            retries--;

            if (retries === 0) {
              // If no more retries left, handle the error
              return this.handleErrorResponse(error);
            } else {
              // Add a delay before the next retry (e.g., 1 second)
              await new Promise((resolve) => setTimeout(resolve, 1000));
            }
          } else {
            return error.response.data;
          }
        } else {
          return this.handleErrorResponse(error);
        }
      }
    }
  }

  async get(path, config) {
    return this.retryRequest(this.service.get.bind(this.service), path, config);
  }

  async patch(path, payload) {
    return this.retryRequest(
      this.service.patch.bind(this.service),
      path,
      payload
    );
  }

  async post(path, payload, headers = { "content-type": "application/json" }) {
    return this.retryRequest(
      this.service.post.bind(this.service),
      path,
      payload,
      { headers }
    );
  }

  async put(path, payload, headers = { "content-type": "application/json" }) {
    return this.retryRequest(
      this.service.put.bind(this.service),
      path,
      payload,
      { headers }
    );
  }

  async delete(path, payload) {
    return this.retryRequest(this.service.delete.bind(this.service), path, {
      data: payload,
    });
  }

  handleErrorResponse(error) {
    if (error && error.response) {
      const { status, data } = error.response;
      console.error("Error response:", { status, data });
      return error.response.data;
    } else {
      console.error("Something went wrong!");
      return { status: 500, data: { message: "Something went wrong!" } };
    }
  }

  async eventSourcePost(
    path,
    payload,
    headers = { "Content-Type": "application/json" }
  ) {
    const url = `${process.env.REACT_APP_API_URL}${path}`;
    const token = process.env.REACT_APP_API_TOKEN;
    const eventSource = new EventSource(url, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
        ...headers,
      },
    });
    console.log("eventSource ", eventSource);
    return eventSource;
  }
}

export default new APIService();
