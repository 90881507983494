import React, { useState } from "react";
import shapeComponent from "../../assets/images/shape-component.svg";
import APIService from "../../utils/APIService";
import { toast } from "react-toastify";
import twitter from "../../assets/images/twitter2.png";
import facebook from "../../assets/images/facebook3.png";
import linkdin from "../../assets/images/linkdin2.png";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      email: email,
    };

    let res = await APIService.post("/waitlist", payload);
    if (res.message) {
      toast.error(res.message);
    } else {
      toast.success("Your email has been registered");
    }
    setEmail("");
  };

  return (
    <footer className="footer">
      <div className="footer-main" id="wishlist">
        <div className="container">
          <div className="footer-main-content">
            <h2 className="title h2">
              Sign Up for Early Access and Exclusive Content
            </h2>
            <p>
              Ready to transform how you manage projects and delight your
              clients? Join our waitlist to get early access to Happy Ending and
              stay updated with the latest insights and tips on delivering
              exceptional service.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="newsletter">
                <input
                  type="email"
                  placeholder="Enter your email to join the waitlist"
                  value={email}
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                />
                <button className="btn" type="submit">
                  Join Now
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="footer-component">
          <img src={shapeComponent} alt="Footer component" />
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-6">
            <div className="social-media">
          <a href="#" className="social-icon">
            <img src={twitter} className="social-img" alt="Twitter" />
          </a>
          <a href="#" className="social-icon">
          <img src={facebook} className="social-img" alt="Facebook" />
            
          </a>
          <a href="#" className="social-icon">
          <img src={linkdin} className="social-img" alt="Linkdin" />
          </a>
        
        </div>
            </div>
            <div className="col-6 text-end">
              <p>All rights reserved © 2024</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
