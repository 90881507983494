import React from 'react';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';

const App = () => (
  <div className="app">
    <Routes />
    <ToastContainer autoClose={2000} />
  </div>
);

export default App;
