import React, { useEffect, useState } from "react";
import "../../assets/styles/style.css";
import popularPost1 from "../../assets/images/popular-post-img_1.png";
import APIService from "../../utils/APIService";
import { calculateDaysDifference } from "../../utils/Helper";
import { Link } from "react-router-dom";
import twitter from "../../assets/images/x.svg";
import facebook from "../../assets/images/facebook.svg";
import linkdin from "../../assets/images/linkedin.svg";

const Sidebar = ({ onCategoryChange, onTagChange }) => {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [popularPosts, setPopularPosts] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeTag, setActiveTag] = useState(null);

  useEffect(() => {
    fetchCatagories();
    fetchPopularPosts();
    fetchTags();
  }, []);

  const fetchCatagories = async () => {
    const catagoriesList = await APIService.get(
      `/blog-categories?page=1&limit=4&filterCategory=true`
    );
    setCategories(catagoriesList);
  };

  const fetchPopularPosts = async () => {
    const popularPostsList = await APIService.get(`/blogs/popular`);
    setPopularPosts(popularPostsList);
  };

  const fetchTags = async () => {
    let tagsList = await APIService.get(`/blog-topics?page=1&limit=4&filterTopics=true`);
    setTags(tagsList);
  };

  const handleCategoryClick = (id) => {
    if (activeCategory === id) {
      setActiveCategory(null);
      onCategoryChange(""); // Send empty string to API
    } else {
      setActiveCategory(id);
      onCategoryChange(id);
    }
  };

  const handleTagClick = (id) => {
    if (activeTag === id) {
      setActiveTag(null);
      onTagChange(""); // Send empty string to API
    } else {
      setActiveTag(id);
      onTagChange(id);
    }
  };

  return (
    <>
      <div className="categories_block">
        <h3 className="title h3">Categories</h3>
        <ul className="links-block filter-list">
          {categories?.blogCategories?.map((item) => (
            <li className="link-item" key={item._id}>
              <a
                onClick={() => handleCategoryClick(item._id)}
                className={activeCategory === item._id ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="topics_block">
        <h3 className="title h3">Tags</h3>
        <ul className="links-block filter-list">
          {tags?.blogTopics?.map((item) => (
            <li className="link-item" key={item._id}>
              <a
                onClick={() => handleTagClick(item._id)}
                className={activeTag === item._id ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="popular-post_block">
        <h3 className="title h3">Popular Posts</h3>
        <ul className="popular-post_item-wrapper">
          {popularPosts?.map((post) => (
            <li key={post._id} className="popular-post_item">
              <Link to={`/blog/${post.slug}`}>
                <div className="popular-post_item-main">
                  <div className="img-block">
                    <img src={post.image} className="post-user" alt={post.title} />
                  </div>
                  <div className="content-block">
                    <span className="title">{post.title}</span>
                    <span>{calculateDaysDifference(post.createdDate)}</span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="join-us_block">
        <h3 className="title h3">Follow us</h3>
        <div className="social-media">
          <a href="#" className="social-icon">
            <img src={twitter} className="social-img" alt="Twitter" />
          </a>
          <a href="#" className="social-icon">
            <img src={facebook} className="social-img" alt="Facebook" />
          </a>
          <a href="#" className="social-icon">
            <img src={linkdin} className="social-img" alt="Linkdin" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
