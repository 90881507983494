import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { useParams } from "react-router-dom";
import "../../assets/styles/style.css";
import Card from "../../components/card/Card";
import Footer from "../../components/footer/Footer";
import pageBanner from "../../assets/images/detail-page-banner.jpg";
import avatarImage from "../../assets/images/nick-user2.png";
import Loader from "../../components/loader/Loader";
import APIService from "../../utils/APIService";
import { extractHeadings, updateHeadingsInHtml } from "../../utils/Helper";
import TableOfContents from "../../components/sidebar/TableOfContents";

const BlogDetails = () => {
  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    fetchBlogDetails(slug);
  }, [slug]);

  const fetchBlogDetails = async (slug) => {
    try {
      const blogData = await APIService.get(`/blogs/detail/${slug}`);
      setLoading(false);
      setBlogDetails(blogData);
      const updatedDescription = updateHeadingsInHtml(
        blogData.blog.description
      );
      setHeadings(extractHeadings(blogData.blog.description)); // Set headings
      setBlogDetails({
        ...blogData,
        blog: {
          ...blogData.blog,
          description: updatedDescription,
        },
      });
    } catch (error) {
      setError(true);
      console.error("Error fetching blog details:", error);
    }
  };

  const formattedDate = new Date(
    blogDetails?.blog.createdDate
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error fetching blog details</div>;
  }

  return (
    <div>
      <Header isBackEnable={true} />
      <main className="blog-details-page">
        <div className="blog-post-detail">
          <div className="container-blog container">
            <div className="row">
              <div className="col-12">
                <div className="content-block">
                  <h1 className="blog-detail-title">
                    {blogDetails?.blog.title}
                  </h1>
                  <div className="avatar-details-cover">
                    <div className="avatar-details">
                      <img
                        src={blogDetails?.blog.authorImage || avatarImage}
                        className="avatar-img"
                        alt={blogDetails?.blog.authorImageName || "Avatar"}
                      />
                      <p className="post-info">
                        {blogDetails?.blog?.authorName || "Admin"}{" "}
                        <span>
                          {blogDetails?.blog.authorDesignation || "CEO"}
                        </span>
                      </p>
                    </div>
                    <p className="post-info">{formattedDate}</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="img-block">
                  <img
                    src={
                      blogDetails?.blog?.image
                        ? blogDetails?.blog.image
                        : pageBanner
                    }
                    className="br-30"
                    alt="Blog Banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="post-sec">
          <div className="container">
            <div className="row">
              <div className="col-8 post-content text-left ul-disc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogDetails?.blog.description,
                  }}
                ></div>
              </div>
              <div className="col-4 post-aside sticky-sidebar">
                <TableOfContents headings={headings} />
              </div>
            </div>
            <div className="related-posts pt-5">
              <ul className="row ps-0">
                {blogDetails?.similarBlogs.map((post, index) => (
                  <Card post={post} key={index} />
                ))}
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BlogDetails;
