import React from "react";
import "../../assets/styles/style.css";
import { useNavigate } from "react-router-dom";
import clientRelationships from "../../assets/images/client-relationships.png";

const Card = ({ post }) => {
  let navigate = useNavigate();

  const viewBlogPost = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <li className="col-md-4 col-sm-6 col-xs-12">
      <div className="post-card">
        <img src={post?.image ? post.image : clientRelationships} alt={post.title} />
        <div className="content">
          <h5 className="title">{post.title}</h5>
          <button
            onClick={() => {
              viewBlogPost(post.slug);
            }}
            className="btn"
          >
            Read More
          </button>
        </div>
      </div>
    </li>
  );
};

export default Card;
