import React from "react";
import logo from "../../assets/images/he1.svg";
import logohover from "../../assets/images/he2hover.svg";
import backIcon from "../../assets/images/back.svg";
import { Link } from "react-router-dom";
import { scrollToId } from "../../utils/Helper";

const Header = ({ isBackEnable }) => {
  return (
    <header className="header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 text-start">
            <Link className="logo" to="/">
              <img src={logo} alt="Happy Ending" class="happy-logo" />
              <img src={logohover} alt="Happy Ending" class="happy-logo2" />
              Happy Ending
            </Link>
          </div>
          <div className="col-6 align-items-center justify-content-end d-flex">
            {isBackEnable && (
              <Link to="/" className="me-3 back-btn">
                {" "}
                <img src={backIcon} alt="Back" /> Back
              </Link>
            )}
            <a href="#wishlist" onClick={(e) => scrollToId(e, "wishlist")}  className="btn secondary">
              Join the Waitlist
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
