import React from "react";
import twitter from "../../assets/images/x.svg";
import facebook from "../../assets/images/facebook.svg";
import linkdin from "../../assets/images/linkedin.svg";
import { toast } from "react-toastify";
import copyIcon from "../../assets/images/copy.svg";

const TableOfContents = ({ headings }) => {
  const blogUrl = window.location.href;
  const encodedUrl = encodeURIComponent(blogUrl);

  // Handle copy to clipboard
  const handleCopyClick = () => {
    navigator.clipboard.writeText(blogUrl).then(() => {
      toast.success("Blog URL copied to clipboard!");
    });
  };

  const handleClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="categories_block">
        <h3 className="title h3" style={{ fontSize: "20px" }}>
          Table of contents
        </h3>

        <ul className="links-block filter-list">
          {headings.map((heading, index) => (
            <li
              className={`link-item heading-level-${heading.level}`}
              key={index}
            >
              <a
                href={`#${heading.id}`}
                onClick={(e) => handleClick(e, heading.id)}
              >
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="join-us_block">
        <span className="table-caption">
          Enjoying this article? Share with Friends!
        </span>

        <div className="social-media">
          <a onClick={handleCopyClick} className="social-icon">
            <img src={copyIcon} className="social-img" alt="Linkdin" />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${encodedUrl}`}
            target="_blank"
            className="social-icon"
          >
            <img src={twitter} className="social-img" alt="Twitter" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
            target="_blank"
            className="social-icon"
          >
            <img src={facebook} className="social-img" alt="Facebook" />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?url=${encodedUrl}`}
            target="_blank"
            className="social-icon"
          >
            <img src={linkdin} className="social-img" alt="Linkdin" />
          </a>
        </div>
      </div>
    </>
  );
};

export default TableOfContents;
